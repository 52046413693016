<template>
  <v-row
    dense
    align="center"
    class="pa-5 pl-0"
  >
    <v-col cols="auto">
      <v-menu
        ref="menuFromDate"
        v-model="menuFromDate"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            dense
            hide-details
            outlined
            class="caption"
            placeholder="From Date"
            :value="dayjs(filters.startDate).format('DD-MM-YYYY')"
            prepend-inner-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="filters.startDate"
          no-title
          scrollable
          @change="menuFromDate = false"
          :max="filters.endDate"
          locale="id"
        >
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="auto">-</v-col>
    <v-col cols="auto">
      <v-menu
        ref="menuToDate"
        v-model="menuToDate"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            dense
            hide-details
            class="caption"
            outlined
            :value="dayjs(filters.endDate).format('DD-MM-YYYY')"
            placeholder="To Date"
            prepend-inner-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          :min="filters.startDate"
          v-model="filters.endDate"
          no-title
          scrollable
          @change="menuToDate = false"
          locale="id"
        >
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="auto" md="4">
      <v-select
        hide-details
        height="10"
        :items="filterByItems"
        class="caption"
        prepend-inner-icon="mdi-filter"
        :placeholder="$_strings.order.FILTER"
        outlined
        dense
        v-model="filters.status"
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'filter-data',
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      filterByItems: [
        {
          text: 'Disetujui',
          value: 'APPROVE',
        },
        {
          text: 'Ditolak',
          value: 'REJECT',
        },
        {
          text: 'Revisi',
          value: 'REVISI',
        },
      ],
      menuFromDate: false,
      menuToDate: false,
    };
  },
  watch: {
    'filters.startDate': function setStartDate(newVal) {
      const { startDate } = this.$route.query;
      if (startDate !== newVal) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            startDate: newVal,
          },
        });
      }
      this.$emit('filterStatus');
    },
    'filters.endDate': function setEndDate(newVal) {
      const { endDate } = this.$route.query;
      if (endDate !== newVal) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            endDate: newVal,
          },
        });
      }
      this.$emit('filterStatus');
    },
    'filters.status': function setStatus(newVal) {
      const { status } = this.$route.query;
      if (status !== newVal) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            status: newVal,
          },
        });
      }
      this.$emit('filterStatus');
    },
  },
  methods: {
    dayjs,
  },
};
</script>

<style lang="scss" scoped>
.v-text-field--outlined.v-input--dense .v-input__control .v-text-field__slot > label.v-label {
  font-size: 10px !important;
  color: red !important;
}
</style>
