<template>
  <v-container fluid>
    <v-row
      justify="space-between"
      class="mt-2 mb-2"
    >
      <v-col>
        <v-btn
          color="primary"
          @click="showDialogEmail"
          v-if="userAccess.canCreate"
        >
          {{ $_strings.email.buttonAdd }}
        </v-btn>
      </v-col>
      <v-col cols="12" sm="5" md="3">
        <v-text-field
          dense
          hide-details
          outlined
          :placeholder="$_strings.email.searchText"
          ref="search"
          v-model="searchText"
          @keyup.enter="search"
        >
          <template v-slot:prepend-inner>
            <v-icon @click="search"> mdi-magnify </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :loading="isLoading"
      :headers="displayedHeaders"
      :items="items"
      item-key="id"
      :server-items-length="totalItems"
      class="body"
      :options.sync="pagination"
      :footer-props="{
        showCurrentPage: true,
        showFirstLastPage: true,
        'items-per-page-options': $_item_per_page,
        disablePagination: isLoading,
        disableItemsPerPage: isLoading
      }">
      <template v-slot:[`item.createdBy`]=items>
        <span>{{ items.item.createdBy }}</span>
        <br/>
        <span>{{ dateFormat(items.item.createdAt) }}</span>
      </template>
      <template v-slot:[`item.actions`]={item}>
        <v-tooltip v-if="userAccess.canUpdate" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn  v-bind="attrs" v-on="on" x-small icon class="mr-2"  color="primary" @click="showDialogEmail(item)" >
              <v-icon> mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>{{$_strings.common.EDIT}}</span>
        </v-tooltip>
        <v-tooltip v-if="userAccess.canDelete" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn  v-bind="attrs" v-on="on" x-small icon color="red" @click="()=> deleteEmail(item)" @click.stop.prevent>
              <v-icon> mdi-trash-can </v-icon>
            </v-btn>
          </template>
          <span>{{$_strings.common.DELETE}}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`footer.page-text`]="props">
        <span>
          {{$_strings.email.pageName}}
          <span v-if="items.length">
            {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
          </span>
        </span>
      </template>
    </v-data-table>
    <AddEmail
      ref="dialogAddEmail"
      @fetchEmail="fetchEmail"
    />
  </v-container>
</template>

<script>
import {
  dateFormat,
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';
import AddEmail from './Dialog/AddEmail.vue';

export default {
  picName: 'email',
  components: {
    AddEmail,
  },
  data() {
    return {
      isLoading: false,
      items: [],
      searchText: '',
      headers: [
        {
          text: this.$_strings.email.nameLabel,
          value: 'picName',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.email.emailLabel,
          value: 'email',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.email.createdBy,
          value: 'createdBy',
          class: 'white--text primary text-capitalize',
        },
        {
          text: 'Aksi',
          value: 'actions',
          class: 'white--text primary text-capitalize',
          sortable: false,
        },
      ],
      pagination: defaultPagination(),
      totalItems: 0,
    };
  },
  computed: {
    displayedHeaders() {
      const { canUpdate, canDelete } = this.userAccess;
      return this.headers.filter((header) => {
        if (header.value === 'actions' && !canUpdate && !canDelete) return;
        return header;
      });
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        if (!this.isLoading) this.fetchEmail();
      },
      deep: true,
    },
  },
  methods: {
    skipEmptyStringObject,
    dateFormat,
    showDialogEmail(item = {}) {
      this.$refs.dialogAddEmail.dialog = true;
      if (item) {
        this.$refs.dialogAddEmail.id = item.id;
        this.$refs.dialogAddEmail.form.email = item.email;
        this.$refs.dialogAddEmail.form.picName = item.picName;
      }
    },
    search() {
      if (this.pagination.page > 1) {
        this.pagination.page = 1;
        return;
      }
      this.fetchEmail();
    },
    fetchEmail() {
      this.isLoading = true;
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        email: this.searchText,
        sort: handleSortBy({ defaultValue: 'createdAt,DESC', sortBy, sortDesc }),
      };
      if (filters.email) filters.email = `qLike(${filters.email})`;
      this.$_services.email.getListEmail(this.skipEmptyStringObject(filters))
        .then((result) => {
          this.items = result.data.contents;
          this.totalItems = result.data.totalData;
        }).finally((e) => {
          this.isLoading = false;
        });
    },
    deleteEmail(item) {
      this.$dialog.warning({
        text: this.$_strings.email.deleteEmailConfirmMsg,
        title: this.$_strings.common.WARNING,
        persistent: true,
        actions: {
          false: this.$_strings.common.NO,
          true: this.$_strings.common.YES,
        },
      }).then((userRes) => {
        if (userRes) {
          this.$root.$loading.show();
          this.$_services.email.deleteEmail(item.id)
            .then(() => {
              this.$dialog.notify.success(this.$_strings.categories.SUCCESS_DELETE_EMAIL_MESSAGE_TEXT);
              this.items.splice(this.items.indexOf(item), 1);
            }).finally(() => {
              this.$root.$loading.hide();
            });
        }
      });
    },
  },
};
</script>
